//TODO - transfer typedef's
/**
 * The complete Triforce, or one or more components of the Triforce.
 * @typedef {Object} FilterValues
 * @property {string} author - Indicates whether the Courage component is present.
 * @property {string} category - Category to filter for
 * @property {string} industry - Industry to filter for
 * @property {string} price - Price to sort for
 * @property {string} searchTerm - Search term to filter for.
 * 
 */


import React, { useState, useEffect} from 'react';
import { useHistory, useParams } from 'react-router-dom';


//semantic
import {
    Grid,
    Message,
    Loader
} from 'semantic-ui-react';


//actions
// import { getPlugins } from '../../store/actions/pluginsActions';

//styles
import styles from "./index.module.css";

// Sub components
// import PluginDetailModal from "./Details/PluginDetailModal";
// import PluginBriefCard from "./Brief/PluginBriefCard";
import DataFilterWrapper from "../DataFilters/DataFilterWrapper";
import { useDispatch, useSelector } from "react-redux";
import { debounce_h, createSemanticDropdownObjects} from "../../utils/formHelpers";
import { alphabeticallySortArrayOfObjectsByProperty } from "../../utils/filterSortHelpers";
import PopulatePluginStore from "../../hoc/PopulatePluginStore/PopulatePluginStore";
import CardList from "./CardList/CardList";
import { searchStringForMatch_h } from "../../utils/filterSortHelpers";
import HelmetSeo from "../HelmetSeo/HelmetSeo";


/**
 * Checks an objects properties to see if all are null or an empty string
 * @param {object} object - object to check
 */
const isEmpty = object=>{
    return Object.values(object).every(x => (x === null || x === ''));
};


/**
 * This function handles the sort/filtering of plugins when a user is searching for specific plugins.
 * @param {Array} plugins Plugins to search/filter/sort
 * @param {FilterValues} filterValues filter values that the user has selected
 */
const filterSortPlugins = (plugins, filterValues)=>{

    var filteredAndSortedPlugins = plugins;

    //return if filter values don't exist
    if(!plugins) {
        return [];
    } 

    //if no filters present - return plugins
    if (isEmpty(filterValues)) {
        return plugins;
    }

    /**
     * FILTERING
     */
    //if a filter criteria is present, then filter the plugins based on that criteria
    if ( filterValues.author || filterValues.rating || filterValues.category || filterValues.searchTerm || filterValues.ratingSort ) {

        filteredAndSortedPlugins = plugins.filter((plugin) => {

            const { authorName, categories, name, tags, rating } = plugin;
            

            if (filterValues.author) {
                if (authorName.toLowerCase() !== filterValues.author.toLowerCase()) {
                    return false;
                }
            }
            
            if (filterValues.category && filterValues.category.length > 0) {
                var categoryMatchFound = false;
                if(!categories || categories.length < 1) return false;
                if (!filterValues.category || filterValues.category.length < 1) return false;

                for (let i = 0; i < filterValues.category.length; i++) {
                    var filteredCategory = filterValues.category[i];
                    var matches = categories.filter(cat => filteredCategory.toLowerCase() === cat.toLowerCase());
                    if(matches.length > 0) {
                        categoryMatchFound = true;
                        break;
                    };
                }
                if(!categoryMatchFound) return false;
            }

            if(filterValues.rating) {
                if (filterValues.rating > rating) return false;
            }


            if (filterValues.searchTerm) {
                //search plugin namea and tags to find a match
                const textToSearch     = name.toLowerCase() + ',' + tags.toLowerCase();
                //create an array of words to search for and filter any empty strings
                var isMatch = searchStringForMatch_h(textToSearch, filterValues.searchTerm);

                if ( !isMatch ) {
                    return false;
                }
            }

            

            return true;
        });
    }
    
    /**
     * SORTING
     */
    switch (filterValues.ratingSort) {
        case 'low':
            filteredAndSortedPlugins = filteredAndSortedPlugins.sort((obj1, obj2) => {
                return obj1.rating - obj2.rating;
            });
            break;
        case 'high':
            filteredAndSortedPlugins = filteredAndSortedPlugins.sort((obj1, obj2) => {
                return obj2.rating - obj1.rating;
            });
            break;
        default:
            break;
    }

    return filteredAndSortedPlugins;
};


/**
 *  Main marketplace area that showcases all plugins
 */
function PluginCards() {

    

    


    /*************************************************
     *  React Router
     *************************************************/
    const history      = useHistory();
    const {pluginName} = useParams();


    /*************************************************
     *  State
     *************************************************/
    const [authorDropdownOptions, setAuthorDropdownOptions] = useState([]);
    const [openModal, setOpenModal]                         = useState(false);
    //plugin passed to modal on popup
    const [modalPlugin, setModalPlugin]                     = useState({});
    //filters selected by the user
    const [filterValues, setFilterValues]                   = useState({
        author: '',
        category: [],
        ratingSort: '',
        rating: '',
        //search term entered in search input
        searchTerm:''
    });


    /*************************************************
     *  Redux
     *************************************************/
    //set up redux dispatch
    const dispatch = useDispatch();
    const { isLoading, error, allPlugins } = useSelector(state => state.plugins);



    /*************************************************
     *  Handlers
     *************************************************/
    const handleCardClick = plugin => {
        setModalPlugin(plugin);
        setOpenModal(true);
    };

    //handles change to one of the filter changes
    const handleFilterChange = debounce_h((event, data) => {
        
    
        setFilterValues(previousState=>{
            return {
                ...previousState,
                [data.name]: data.value
            };
        });
        
       
    }, 100, false);

    

    /*************************************************
     *  Effects
     *************************************************/
    //create dropdowns for authors
    useEffect(() => {
        //Creates necessary information for dropdowns based on plugins
        if ( allPlugins.length > 0 ) {

            var authorDropdownOptions = createSemanticDropdownObjects(allPlugins, "authorName");
            authorDropdownOptions     = alphabeticallySortArrayOfObjectsByProperty(authorDropdownOptions, "text");

            
            setAuthorDropdownOptions(authorDropdownOptions);
        }
    }, [allPlugins.length, dispatch, allPlugins]);


    //this is used if a person goes directly to a plugin, it will forward user to the plugin of interest
    useEffect(()=>{
       
        //used to handle when a user comes directly to a plugin (dont' navigate from within the application to a plugin and are given a direct link)
        if (!(document.referrer && document.referrer.length > 0 && (document.referrer.indexOf('oasisapps') > 0 || document.referrer.indexOf('localhost'))) && allPlugins.length > 0) {
         
            let directPlugin = allPlugins.find(plugin=>{
                if(plugin.seo && plugin.seo.productName) {
                    return plugin.seo.productName === pluginName;
                }
            });

            if(directPlugin) {
                history.push({
                    pathname: `/marketplace/${directPlugin.seo.productName}`,
                    state: {
                        pluginModalIsOpen: true,
                        plugin: directPlugin
                    }
                });
            }
        }
    }, [allPlugins]);


    return (
        <>
            {/* SEO HELMET */}
            <HelmetSeo 
                title="Quickbase Plugins"
                description="The Oasis Quickbase plugin marketplace showcases all of the plugins currently available to install on top of your Quickbase applications.  Search and filter the Quickbase plugins and install a plugin in a matter of minutes."
            />
            {/* SEO HELMET */}
            

            
            <PopulatePluginStore>

                <section className={styles['marketplace-page--wrapper']}>

                    {/* Data filter */}
                    <DataFilterWrapper
                        onFilterChange={handleFilterChange}
                        authorOptions={authorDropdownOptions}
                    />
                    {/* Data filter */}

                    {/* Errors */}
                    {error && 
                        <Grid centered>
                            <Grid.Column mobile={16} tablet={10} computer={10} largeScreen={10} widescreen={4}>
                                <Message negative>
                                    <Message.Header>Error!</Message.Header>
                                    <p>There was an error loading the plugins.  Please try again later. {error}</p>
                                </Message>
                            </Grid.Column>
                        </Grid>
                    }
                    {/* Errors */}


                    {/* Loading Gif */}
                    {isLoading && !error &&
                        <div className={styles['marketplace-page--loader']}>
                            <Loader active inline='centered' size='large'><span>Loading Plugins...</span></Loader>
                        </div>
                    }
                    {/* Loading Gif */}

                    
                    {/* Main Content */}
                    {!isLoading && !error && allPlugins &&
                        <>
                            <CardList 
                                filterValues={filterValues}
                                allPlugins={allPlugins}
                                onCardClick={handleCardClick}
                                onFilter={filterSortPlugins}
                            />
                        </>
                    }
                    {/* Main Content */}
                </section>

            </PopulatePluginStore>

            {/*Show the detail of the plugin*/ }
            {/* <PluginDetailModal
                openModal={openModal}
                setOpenModal={setOpenModal}
                plugin={modalPlugin}
            /> */}
        </>
    );

}

export default PluginCards;