/**  
*   File Puprose: Production configuration for project.  Overrides any values from "default.js" in production
*   i.e when "NODE_ENV === production" these configs take precedence over "default.js"
*/


//due to there being 3 different instances of Oasis - this conditionally controls what API url is being hit
var API_URL                 = 'https://oasisappsapi.com';
var AUTHORIZE_PUBLIC_KEY    = "3LHK7zbzwC6268s93CBUvtdwgCz34LBxvZ76UY9h5bj34eqybgtFDRZSsYj59rFT";
var AUTHORIZE_MERCHANT_NAME = "9g6q6kWCK";
var ENVIRONMENT             = 'prod';


if (window.location.href.indexOf('dev.oasisapps') > 0 || window.location.href.indexOf('addons') > 0 ) {
    API_URL                 = "https://oasis.service.addons.myquickbase.net";
    AUTHORIZE_PUBLIC_KEY    = "4K6dhQz94ENzUSWB7FQX6Ks6ZKcJR9q998rAVeqX5sqsRF7eG8xmb93km7Z3FuW5";
    AUTHORIZE_MERCHANT_NAME = "8K34Hgn46";
    ENVIRONMENT             = 'dev';
}

if (window.location.href.indexOf('staging.oasisapps') > 0) {
    API_URL                 = 'https://staging.oasisappsapi.com';
    AUTHORIZE_PUBLIC_KEY    = "4K6dhQz94ENzUSWB7FQX6Ks6ZKcJR9q998rAVeqX5sqsRF7eG8xmb93km7Z3FuW5";
    AUTHORIZE_MERCHANT_NAME = "8K34Hgn46";
    ENVIRONMENT             = 'dev';
}

//deprecated develoment environment
if (window.location.href.indexOf('devmcfoasfrontend') > 0) {
    API_URL                 = 'https://devmcfoas.com';
    AUTHORIZE_PUBLIC_KEY    = "4K6dhQz94ENzUSWB7FQX6Ks6ZKcJR9q998rAVeqX5sqsRF7eG8xmb93km7Z3FuW5";
    AUTHORIZE_MERCHANT_NAME = "8K34Hgn46";
    ENVIRONMENT             = 'dev'
}




/*
    PRODUCTION CONFIGS
*/
const configs = {
    API_URL,
    AUTHORIZE_PUBLIC_KEY,
    AUTHORIZE_MERCHANT_NAME,
    ENVIRONMENT
};


export default configs;