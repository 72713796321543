import React, {useState} from 'react';

//semantic
import {Header, Icon} from 'semantic-ui-react';

import {Link} from "react-router-dom";

import VideoTutorialPlayer from "../VideoTutorialPlayer/VideoTutorialPlayer";

//styles
import styles from './index.module.css';

function Home() {

    /*****************************************
     * State 
     *****************************************/
    const [videoShowing, setVideoShowing] = useState(false);

    const handleLaunchVideo = e => {
        setVideoShowing(true);
    };

    const handleVideoToggle = (bool) => {
        setVideoShowing(bool);
    };


    return (
        <>

            <VideoTutorialPlayer
                videos={[{
                    id: "jmWcp0uDit8",
                }]}
                open={videoShowing}
                autoplay={true}
                openHandler={handleVideoToggle}
            />
            

            <div className={styles["home-wrapper"]}>
                <Header
                    color="teal"
                    size="huge"
                    textAlign="center"
                    className={styles["header-mod"]}
                >
                    SIMPLE, <span className={styles["header-mod--gold"]}>POWERFUL</span>, EXTENSIBLE 
                    <br />
                    QUICKBASE PLUGINS
                </Header>

                <div className={styles["home-info-blurb"]}>
                    Extend your Quickbase applications,
                    <br/>
                    with no code, in minutes!
                </div>
                <div className={styles["home-video-icon"]}>
                    <div className={styles["home-video-icon__display"]}>
                        <Icon
                            onClick={handleLaunchVideo}
                            className={styles["home-video-icon__icon"]}
                            name="video play"
                        />
                    </div>
                </div>
                <div className={styles["video-subtitle"]}>
                    See it in Action
                </div>
                
                <div className={styles["home-cta"]}>
                    <Link to="/signup">
                        <button className={styles["home-cta__button"]}>Get Started Today!</button>
                    </Link>
                </div>
            </div>
        </>
    );

}

export default Home;